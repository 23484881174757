import React, { useEffect, useState } from 'react';
import { Formik, } from "formik";
import { Post, Get } from '../../common/ajax';
import { Form, Button } from "react-bootstrap";
import { Link, useParams, useNavigate } from 'react-router-dom';
import { immediateToast } from 'izitoast-react';
import Logo from '../../../images/logos/MANOGATI LOGO2.png';
import * as Yup from "yup";
import moment from "moment";
import Swal from "sweetalert2";
import { AppointmentStatus, ConversationTypeStatus } from '../enum';
import Select from 'react-select';

const validation = Yup.object().shape({
    appointmentDate: Yup.string().required(),
    appointmentTime: Yup.string().required(),
    conversationType: Yup.string().required()
});
const AppointmentAdd = () => {
    let params = useParams();
    const navigate = useNavigate();
    const [formkey, setFormKey] = useState(Math.random() * 1000000);
    const [initValue, setinitValue] = useState({
        doctorId: "",
        patientId: "",
        paymentAmount: 0,
        appointmentDate: "",
        appointmentTime: "",
        status: "",
        reason: "",
        conversationType: "",
        specializationId: ""
    });
    const clear = () => {
        setinitValue({
            doctorId: "",
            patientId: "",
            paymentAmount: 0,
            appointmentDate: "",
            appointmentTime: "",
            status: "",
            reason: "",
            conversationType: "",
            specializationId: ""
        });
    }
    const [drData, setDrData] = useState([]);
    const [patientData, setPatientData] = useState([]);
    const [specialization, setSpecialization] = useState([]);
    const [minDate, setMinDate] = useState('');
    const [extDoc, setextDoc] = useState({});
    const [selectedDoc, setselectedDoc] = useState('');
    const [extPat, setextPat] = useState({});
    const [selectedPat, setselectedPat] = useState('');
    const [msgtitle, setmsgtitle] = useState("");
    const [availability, setAvailability] = useState([]);
    const [availableApptTypes, setAvailableApptTypes] = useState([]);

    useEffect(() => {
        if (params.id) {
            getById();
        }
        //getDoctors(true);
        getPatients(true);
        getSpecialization(true);
        const tomorrow = new Date();
        tomorrow.setDate(tomorrow.getDate() + 1);
        const formattedDate = tomorrow.toISOString().split('T')[0];
        setMinDate(formattedDate);
    }, []);
    const showTost = (msg, theme) => {
        theme = theme || "info";
        immediateToast(theme, {
            message: msg,
            timeout: 3000,
            position: "topRight"
        });
    }
    const clearData = () => {
        Swal.fire({
            title: "All unsaved data will be lost. Do you want to proceed?",
            showCancelButton: true,
            confirmButtonText: "Yes",
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                navigate('/appointment');
            } else if (result.isDenied) {
                //Swal.fire("Changes are not saved", "", "info");
            }
        });

    }

    const Checkavailability = async (doctorId, appointmentDate) => {
        if (doctorId && appointmentDate) {
            try {
                Post(`doctors/Checkavailability`, { doctorId: doctorId, appointmentdate: appointmentDate })
                    .then((res) => {
                        if (!res.success) {
                            showTost(res.message);
                        } else {
                            if (!params.id) {
                                setmsgtitle(res.data?.message);
                            }
                            else {
                                setmsgtitle("");
                            }
                            if (params.id) {
                                setAvailability(res.data?.Timeslots);
                            }
                            else {
                                setAvailability(res.data?.filteredTimeslots);
                            }
                        }
                    })
            } catch (err) {
                showTost("Error while checking availability" + err.message, "error");
            }
        }
    };
    const getDoctorById = async (doctorId, type, setFieldValue) => {
        if (selectedDoc) {
            Get(`doctors/getDoctorById/${selectedDoc}`)
                .then((res) => {
                    if (res.success) {
                        if (type) {
                            const hourPayment = res.data.paymentId?.payment?.find(p => p.period == "Hour");
                            if (hourPayment) {
                                const voiceAmount = hourPayment.typeamount.find(t => t.conversationType == type);
                                if (voiceAmount) {
                                    setFieldValue('paymentAmount', voiceAmount.amount || 0);
                                    return;
                                }
                            }
                        }
                        setFieldValue('paymentAmount', 0);
                    }
                    else {
                        showTost(res.data.message, "warning");
                        setFieldValue('paymentAmount', 0);
                    }
                })
                .catch((err) => {
                    showTost("Error in getting Doctor Detail." + err.message, "warning");
                    setFieldValue('paymentAmount', 0);
                });
        }
        else {
            setFieldValue('paymentAmount', 0);
        }
    };
    const handleDateChange = (e, setFieldValue, values) => {
        const appointmentDate = e.target.value;
        setFieldValue('appointmentDate', appointmentDate);
        Checkavailability(selectedDoc, appointmentDate);
    };
    const getDoctors = async (special) => {
        if (!special) return;
        setDrData([]);
        setextDoc({});
        setselectedDoc("");
        Post(`doctors/getDoctors`, { active: true, special: special })
            .then(async (res) => {
                if (res.success) {

                    var doctorFilter = res.data
                        .filter((doctor) =>
                            doctor.specialization.some(
                                (spec) => spec._id === special
                            )
                        )
                    var doctorsList = [];
                    for (var i = 0; i < doctorFilter.length; i++) {
                        var name = doctorFilter[i].firstName + " " + doctorFilter[i].middleName + " " + doctorFilter[i].lastName;
                        var id = doctorFilter[i]._id;
                        doctorsList.push({ data: id, value: id, label: name })
                    }
                    setDrData(doctorsList);
                }
                else {
                    showTost(res.message, "warning");
                }
            })
            .catch((err) => {
                showTost("Error in getting Doctor List" + err.message, "warning");
            });
    }
    const getPatients = async (active) => {
        Post(`patients/getPatients`, { active: active })
            .then(async (res) => {
                if (res.success) {
                    var patientList = [];
                    await res.data.map((pat, i) => {
                        patientList.push({ data: pat._id, value: pat._id, label: pat.firstName + " " + pat.middleName + " " + pat.lastName })
                    })
                    setPatientData(patientList);
                }
                else {
                    showTost(res.message, "warning");
                }
            })
            .catch((err) => {
                showTost("Error in getting Patient List" + err.message, "warning");
            });
    }
    const getSpecialization = async (active) => {
        Get(`masters/getSpecialization/${active}`)
            .then((res) => {
                if (res.success) {
                    setSpecialization(res.data);
                }
                else {
                    showTost(res.message, "warning");
                }
            })
            .catch((err) => {
                showTost("Error in getting Specialization List" + err.message, "warning");
            });
    }
    const handleSubmit = async (value) => {
        let val = { ...value };
        val.addedBy = "Admin";
        val.doctorId = selectedDoc;
        val.patientId = selectedPat;
        Post(`appointment/addAppointment`, val)
            .then((res) => {
                if (res.success == false) {
                    showTost(res.message);
                    return false;
                }
                else {
                    showTost(res.message);
                    navigate('/appointment');
                }
            })
            .catch((err) => {
                showTost("Error in getting when Appointment data save" + err.message, "warning");
            });
        // if (!selectedPatient) {
        //     showTost("Selected patient details not found.", "error");
        //     return;
        // }          
        // const orderResponse = await Post("appointment/createOrder", {
        //     amount: value.paymentAmount * 100,
        //     currency: "INR",
        //     appointmentId: value.appointmentId || null,
        // });
        // if (!orderResponse || !orderResponse.success) {
        //     showTost("Error creating payment order. Please try again.", "error");
        //     return;
        // }
        // const { order_id } = orderResponse.data;
        // const options = {
        //     key: process.env.REACT_APP_RAZORPAY_KEY_ID,
        //     amount: value.paymentAmount * 100,
        //     currency: "INR",
        //     name: "GroTrade",
        //     description: "Test Transaction",
        //     image: { Logo },
        //     order_id,
        //     handler: async function (response) {
        //         const paymentData = {
        //             razorpayPaymentId: response.razorpay_payment_id,
        //             razorpayOrderId: response.razorpay_order_id,
        //             razorpaySignature: response.razorpay_signature,
        //             appointmentData: { ...value, addedBy: "Admin" },
        //         }
        //         console.log("payment success", paymentData )

        //         try {                   
        //             const res = await Post(`appointment/addAppointment`, {
        //                 ...paymentData.appointmentData,
        //                 razorpayPaymentId: paymentData.razorpayPaymentId,
        //                 razorpayOrderId: paymentData.razorpayOrderId,
        //                 razorpaySignature: paymentData.razorpaySignature,
        //             });    
        //             if (!res.success) {
        //                 showTost(res.message);
        //             } else {
        //                 showTost(res.message);
        //                 navigate('/appointment');
        //             }
        //         } catch (err) {
        //             showTost(`Error while saving appointment: ${err.message}`, "error");
        //         }
        //     },

        //     prefill: {
        //         name: `${selectedPatient.firstName} ${selectedPatient.middleName || ""} ${selectedPatient.lastName}`,
        //         email: selectedPatient.email,
        //         contact: selectedPatient.mobileNo 
        //     },
        //     theme: {
        //         color: "#3399cc",
        //     },
        // };
        // const razorpay = new window.Razorpay(options);
        // razorpay.open();
        // razorpay.on("payment.failed", async function (response) {
        //     try {
        //         await Post("appointment/addAppointment", {
        //             ...value,
        //             paymentStatus: "failed",
        //             razorpayOrderId: order_id,
        //             razorpayPaymentId: null,
        //             razorpaySignature: null,
        //         });
        //         showTost("Payment failed. Please try again.", "error");
        //     } catch (err) {
        //         showTost(`Error updating payment status: ${err.message}`, "error");
        //     }
        // });

    };
    const getById = async () => {
        Get(`appointment/getAppointmentById/${params.id}`)
            .then((res) => {
                const appointmentDate = moment(res.data.appointmentDate).format('YYYY-MM-DD');
                Checkavailability(res.data.doctorId?._id, appointmentDate);
                getDoctors(res.data.specializationId);
                setAvailableApptTypes(ConversationTypeStatus);
                setinitValue({
                    id: params.id,
                    appointmentDate: appointmentDate,
                    paymentAmount: res.data.paymentAmount,
                    appointmentTime: res.data.appointmentTime + " - " + res.data.endTime,
                    specializationId: res.data.specializationId,
                    doctorId: res.data.doctorId?._id,
                    patientId: res.data.patientId?._id,
                    status: res.data.status,
                    conversationType: res.data.conversationType
                });
                setselectedDoc(res.data.doctorId);
                setselectedPat(res.data.patientId);
                setFormKey(Math.random() * 1000000);
            })
            .catch((err) => {
                showTost("Error getting in appointment detail  " + err.message, "error");
            });
    }

    useEffect(() => {
        var doc = drData.filter(x => x.data = selectedDoc);
        console.log(doc, "doc");
        setextDoc(doc);
    }, [drData])

    useEffect(() => {
        var pat = patientData.filter(x => x.data = selectedPat);
        setextPat(pat);
    }, [patientData])

    const handleEdit = async (val) => {
        val.id = params.id;
        val.addedBy = "Admin";
        await Post(`appointment/editAppointment`, val)
            .then((res) => {
                if (res.success == true) {
                    clear();
                    Swal.fire({
                        title: "Your work has been saved",
                        icon: "success",
                        confirmButtonText: "OK",
                        confirmButtonColor: "#FD711A",
                    }).then(async ({ isConfirmed }) => {
                        if (isConfirmed) {
                            navigate('/appointment');
                        }
                    });
                }
                else {
                    showTost(res.message, "warning");
                }
            })
            .catch((err) => {
                showTost("Error getting when appointment data update" + err.message, "error");
            });
    }
    const handleAppointmentTimeChange = (e) => {
        const selectedTime = e.target.value.split(" -")[0];

        const selectedSlot = availability.find(slot => slot.From === selectedTime);

        if (selectedSlot) {
            setAvailableApptTypes(selectedSlot.apptType);
        } else {
            setAvailableApptTypes([]);
        }
    };
    const handleDocChange = (option) => {
        console.log(option);
        setselectedDoc(option.value);
        setextDoc(option);
    }
    const handlePatChange = (option) => {
        console.log(option, "pat");
        setselectedPat(option.value);
        setextPat(option);
    }
    return (
        <div className="container-fluid">
            <Formik
                enableReinitialize={true}
                onSubmit={params.id ? handleEdit : handleSubmit}
                initialValues={initValue}
                validationSchema={validation}
                key={formkey}
            >
                {({
                    handleSubmit,
                    handleChange,
                    values,
                    touched,
                    errors,
                    setFieldValue,

                }) => {
                    return (<>
                        <div className="row pt-2 pb-2">
                            <div className="col-sm-10">
                                <h4 className="page-title">{params.id ? "Edit Appointment" : "Add Appointment"}</h4>
                            </div>
                            {/* <div className="col-sm-2">
                                <div className="btn-group float-sm-right">
                                    <Link to={"/appointment"}><button type="button" className="btn btn-light waves-effect waves-light"><i className="fa fa-bars mr-1" /> View Appointments</button></Link>
                                </div>
                            </div> */}
                        </div>
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="card">
                                    <div className="card-body">
                                        <Form
                                            onSubmit={handleSubmit}
                                            onChange={handleChange}>
                                            <div className='row mb-3'>
                                                <div className=' col-lg-6 col-xl-6 col-12 mt-2'>
                                                    <label>Select Specialization Of Doctor</label>
                                                    <select name="specializationId" value={values.specializationId} className={!!touched.specializationId && !!errors.specializationId ? "form-control is-invalid" : "form-control"} onChange={(e) => { getDoctors(e.target.value); setFieldValue('doctorId', ''); }}>
                                                        <option value="">Select Specialization</option>
                                                        {specialization
                                                            .map((x, i) => (
                                                                <option key={i} value={x._id}>{x.name}</option>
                                                            ))}
                                                    </select>
                                                </div>
                                                <div className=' col-lg-6 col-xl-6 col-12 mt-2'>
                                                    <label>Select Doctor</label>
                                                    <Select
                                                        name="doctorId"
                                                        className={!!touched.doctorId && !!errors.doctorId ? "is-invalid" : ""}
                                                        classNamePrefix="select"
                                                        // isClearable={true}
                                                        isSearchable={true}
                                                        options={drData}
                                                        value={extDoc}
                                                        onChange={handleDocChange}
                                                    />
                                                    <span className='text-danger'></span>
                                                </div>
                                                <div className=' col-lg-6 col-xl-6 col-12 mt-2'>
                                                    <label>Appointment Date</label>
                                                    <input type='date' name='appointmentDate' value={values.appointmentDate}

                                                        className={!!touched.appointmentDate && !!errors.appointmentDate ? "form-control is-invalid" : "form-control"}
                                                        onChange={(e) => handleDateChange(e, setFieldValue, values)} min={minDate} />
                                                    <span className='text-danger'>{msgtitle}</span>
                                                </div>
                                                <div className=' col-lg-6 col-xl-6 col-12 mt-2'>
                                                    <label>Appointment Time</label>
                                                    <select
                                                        name="appointmentTime"

                                                        value={values.appointmentTime}
                                                        className={!!touched.appointmentTime && !!errors.appointmentTime ? "form-control is-invalid" : "form-control"}
                                                        onChange={(e) => { handleAppointmentTimeChange(e); handleChange(e) }}>
                                                        <option value="">Select Appointment Time</option>
                                                        {availability.map((slot, i) => (
                                                            <option key={i} value={`${slot.From} - ${slot.To}`}>
                                                                {`${slot.From} - ${slot.To}`}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>

                                                <div className=' col-lg-6 col-xl-6 col-12 mt-2'>
                                                    <label>Select Patient</label>
                                                    <Select
                                                        name="patientId"
                                                        className={!!touched.patientId && !!errors.patientId ? "is-invalid" : ""}
                                                        classNamePrefix="select"
                                                        // isClearable={true}
                                                        isSearchable={true}
                                                        options={patientData}
                                                        value={extPat}
                                                        onChange={handlePatChange}
                                                    />
                                                </div>
                                                {params.id ?
                                                    <>
                                                        <div className=' col-lg-6 col-xl-6 col-12 mt-2'>
                                                            <label>Select Status</label>
                                                            <select name="status" value={values.status} className={!!touched.status && !!errors.status ? "form-control is-invalid" : "form-control"}>
                                                                <option value="">Select Status</option>
                                                                {AppointmentStatus.map((x, i) => (
                                                                    <option key={i} value={x}>
                                                                        {x}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                    </> : ""}
                                                <div className=' col-lg-6 col-xl-6 col-12 mt-2'>
                                                    <label>Select Conversation Type</label>
                                                    <select name="conversationType" onChange={(e) => getDoctorById(values.doctorId, e.target.value, setFieldValue, values)} value={values.conversationType} className={!!touched.conversationType && !!errors.conversationType ? "form-control is-invalid" : "form-control"}>
                                                        <option value="">Select Conversation Type</option>
                                                        {availableApptTypes.map((type, i) => (
                                                            <option key={i} value={type}>
                                                                {type}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                                <div className=' col-lg-6 col-xl-6 col-12 mt-2'>
                                                    <label>Payment</label>
                                                    <div>{values.paymentAmount} RS.</div>
                                                </div>
                                            </div>
                                            <div className="col-12 pr-0">
                                                <Button type="reset" className="btn btn-danger waves-effect waves-light float-right ml-2" onClick={() => clearData()}>Cancel</Button>
                                                <Button type="submit" className={(params.id ? "btn-success" : "btn-light") + " btn waves-effect waves-light float-right"}>{params.id ? "Update" : "Add New"}</Button>
                                            </div>
                                        </Form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                    );
                }}
            </Formik>
        </div>
    )
}

export default AppointmentAdd
