import React, { useEffect, StrictMode, useState, useRef } from 'react';
import { Post } from '../../common/ajax';
import AgoraRTC from 'agora-rtc-sdk-ng';
import { useParams } from 'react-router-dom';
import VideoMeeting  from './VideoMeeting';

const Meeting = () => {
    return (
        <>
            <VideoMeeting />
        </>
    )
}
export default Meeting;