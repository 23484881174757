import React, { useEffect, useState } from 'react';
import axios from "axios";
import { Link } from 'react-router-dom';
import { CModal } from '@coreui/react';
import { Formik, Field, ErrorMessage } from "formik";
import { Form, Button } from "react-bootstrap";
import { immediateToast } from 'izitoast-react';
import { Post, Get } from '../common/ajax';
import * as Yup from "yup";
import { useIdleTimer } from 'react-idle-timer'

const addDataSchema = Yup.object().shape({
  currentPassword: Yup.string().required('Current Password is required'),
  newPassword: Yup.string().min(6).required('New Password is required'),
  ConfirmNewPassword: Yup.string().required('Confirm Password is required')
    .oneOf([Yup.ref('newPassword')], 'Passwords must and should match'),
});
const initValue = {
  currentPassword: "",
  newPassword: "",
  ConfirmNewPassword: "",
};
const Header = () => {
  let userId = JSON.parse(window.localStorage.getItem("AdminUser"))
  let email = JSON.parse(window.localStorage.getItem("Email"));
  let userName = JSON.parse(window.localStorage.getItem("AdminUsername"));

  const [passwordmodal, setpasswordmodal] = useState(false);
  const [currentPasswordVisible, setCurrentPasswordVisible] = useState(false);
  const [newPasswordVisible, setNewPasswordVisible] = useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
  const onIdle = () => {
    logout();
  }

  const onActive = () => {
    //console.log('Active')
  }

  const onAction = () => {
    //console.log("OnAction")
    // let d = new Date();
    // var nD = d.setMinutes(d.getMinutes() + 10);
    // console.log(nD);

  }

  const { getRemainingTime } = useIdleTimer({
    onIdle,
    onActive,
    onAction,
    timeout: 500000,
    throttle: 500
  })

  useEffect(() => {
    const interval = setInterval(() => {
      var exTime = window.localStorage.getItem("ExpiryTime");
      if (!exTime) {
        let d = new Date();
        exTime = d.setMinutes(d.getMinutes() - 1);
        window.localStorage.setItem("ExpiryTime", exTime);
      }
      var nTime = JSON.parse(exTime);
      //console.log(new Date(nTime), "exDate");

      if (new Date() > new Date(nTime)) {
        logout();
      }

    }, 2000)

    return () => {
      clearInterval(interval)
    }
  })
  const logout = () => {
    window.localStorage.removeItem("AdminToken");
    window.localStorage.removeItem("AdminUser");
    window.localStorage.removeItem("Email");
    window.localStorage.removeItem("AdminUsername");
    window.localStorage.removeItem("AdminRole");
    window.localStorage.removeItem("PasswordModalShown");
    window.localStorage.removeItem("ExpiryTime");
    window.location.reload();
    window.location.replace('/index');
  }
  const showTost = (msg, theme) => {
    theme = theme || "info";
    immediateToast(theme, {
      message: msg,
      timeout: 3000,
      position: "topRight"
    });
  }
  const handleSubmit = async (val) => {
    val.id = JSON.parse(window.localStorage.getItem("AdminUser"));
    Post(`users/changePassword`, val)
      .then((res) => {
        if (res.message) {
          showTost(res.message, "warning");
        }
        else {
          logout();
        }
      })
      .catch((err) => {
        showTost("Something went wrong please try after sometime", "error");
      });
  };
  const closePasswordModal = () => {
    setpasswordmodal(false);
    window.localStorage.setItem('PasswordModalShown', "false");
  };
  useEffect(() => {
    const modalShown = window.localStorage.getItem('PasswordModalShown');
    if (modalShown == "true") {
      setpasswordmodal(true);
    }
  }, []);
  return (
    <>
      <CModal alignment="center" visible={passwordmodal} onClose={closePasswordModal}>
        <Formik
          onSubmit={handleSubmit}
          initialValues={initValue}
          validationSchema={addDataSchema}
        >
          {({
            handleSubmit,
            handleChange,
            values,
            touched,
            errors,
            setFieldValue,
          }) => {
            return (<>
              <div className="modal-header" style={{ borderBottom: "1px solid #e9ecef" }}>
                <h3>Change Password</h3>
                <button type="button" className="close" onClick={() => setpasswordmodal(false)}>&times;</button>
              </div>
              <Form
                onSubmit={handleSubmit}
                onChange={handleChange}>
                <div className="modal-body">
                  <label className>Old Password</label>
                  <div className="position-relative has-icon-right">
                    <Field type={currentPasswordVisible ? "text" : "password"} name="currentPassword" className={`form-control ${touched.currentPassword && errors.currentPassword ? "is-invalid" : ""}`} />
                    <div className="form-control-position">
                      <i 
                        className={currentPasswordVisible ? "fa fa-eye" : "fa fa-eye-slash"} 
                        onClick={() => setCurrentPasswordVisible(!currentPasswordVisible)} 
                        style={{ cursor: "pointer" }} 
                      />
                    </div>
                  </div>
                  <ErrorMessage name="currentPassword" component="span" className="error" />
                  <br />
                  <label className>New Password</label>
                  <div className="position-relative has-icon-right">
                    <Field type={newPasswordVisible ? "text" : "password"}  name="newPassword" className={`form-control ${touched.newPassword && errors.newPassword ? "is-invalid" : ""}`} />
                    <div className="form-control-position">
                      <i 
                        className={newPasswordVisible ? "fa fa-eye" : "fa fa-eye-slash"} 
                        onClick={() => setNewPasswordVisible(!newPasswordVisible)} 
                        style={{ cursor: "pointer" }} 
                      />
                    </div>
                  </div>
                  <ErrorMessage name="newPassword" component="span" className="error" />
                  <br />
                  <label className>Confirm Password</label>
                  <div className="position-relative has-icon-right">
                    <Field type={confirmPasswordVisible ? "text" : "password"}  name="ConfirmNewPassword" className={`form-control ${touched.ConfirmNewPassword && errors.ConfirmNewPassword ? "is-invalid" : ""}`} />
                    <div className="form-control-position">
                      <i 
                        className={confirmPasswordVisible ? "fa fa-eye" : "fa fa-eye-slash"} 
                        onClick={() => setConfirmPasswordVisible(!confirmPasswordVisible)} 
                        style={{ cursor: "pointer" }} 
                      />
                    </div>
                  </div>
                  <ErrorMessage name="ConfirmNewPassword" component="span" className="error" />
                </div>
                <div className="modal-footer" style={{ borderTop: "1px solid #e9ecef" }}>
                  <div className="col-12">
                    <button type="submit" className="btn btn-warning float-right mt-3 sc11">Change Password</button>
                  </div>
                </div>
              </Form>
            </>
            );
          }}
        </Formik>
      </CModal>
      <header className="topbar-nav">
        <nav id="header-setting" className="navbar navbar-expand fixed-top">
          <ul className="navbar-nav mr-auto align-items-center">
            <li className="nav-item">
              <a className="nav-link toggle-menu" href="#">
                <i className="icon-menu menu-icon" />
              </a>
            </li>
            <li className="nav-item">
              <form className="search-bar">
                <input type="text" className="form-control" placeholder="Enter keywords" />
                <a href="#"><i className="icon-magnifier" /></a>
              </form>
            </li>
          </ul>
          <ul className="navbar-nav align-items-center right-nav-link">
            <li className="nav-item">
              <a className="nav-link dropdown-toggle dropdown-toggle-nocaret" data-toggle="dropdown" href="#">
                <span className="user-profile"> <i className="fa fa-user-circle mr-3 mt-1" style={{ fontSize: '24px' }} />
                </span>
              </a>
              <ul className="dropdown-menu dropdown-menu-right">
                <li className="dropdown-item user-details">
                  <a href="#">
                    <div className="media">
                      <div className="avatar"> <i className="fa fa-user-circle mr-3 mt-1" style={{ fontSize: '40px' }} />
                      </div>
                      <div className="media-body">
                        <h6 className="mt-2 user-title">{userName}</h6>
                        <p className="user-subtitle">{email}</p>
                      </div>
                    </div>
                  </a>
                </li>
                {/* <li className="dropdown-divider" />
                <li className="dropdown-item"><i className="icon-envelope mr-2" /> Inbox</li>
                <li className="dropdown-divider" />
                <li className="dropdown-item"><i className="icon-wallet mr-2" /> Account</li>
                <li className="dropdown-divider" />
                <li className="dropdown-item"><i className="icon-settings mr-2" /> Setting</li> */}
                <li className="dropdown-divider" />
                <li className="dropdown-item" onClick={() => setpasswordmodal(true)}><i className="icon-key mr-2" /> Change Password</li>
                <li className="dropdown-divider" />
                <li className="dropdown-item" onClick={() => logout()}><i className="icon-power mr-2" /> Logout</li>
              </ul>
            </li>
          </ul>
        </nav>
      </header>
    </>
  );
}

export default Header;