import React, { useEffect, useState } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { immediateToast } from 'izitoast-react';
import { Formik, Field, ErrorMessage } from "formik";
import { Form, Button } from "react-bootstrap";
import * as Yup from "yup";
import { Post, Get } from '../../common/ajax';
const addDataSchema = Yup.object().shape({
  name: Yup.string().required("Role Name field is required"),
});
const RoleAdd = () => {
  let params = useParams();
  const navigate = useNavigate();
  const clearData = () => {
    navigate('/RoleMaster');
  }
  useEffect(() => {
    if (params.id) {
      getById();
    }
  }, []);

  const [initValue, setValue] = useState({
    id: "",
    name: "",
    is_active: true,
  });
  const showTost = (msg, theme) => {
    theme = theme || "info";
    immediateToast(theme, {
      message: msg,
      timeout: 3000,
      position: "topRight"
    });
  }
  const getById = async () => {
    Get(`masters/getRoleById/${params.id}`)
        .then((res) => {
            if (res) {
              setresponcevalue(res);
            }
        })
        .catch((err) => {
            showTost("Error in getting User detail: " + err.message, "warning");
        });
};
  const setresponcevalue = async (res) => {
    setValue({
      id: params.id,
      name: res.name    
    });
  }
  const handleSubmit = async (value) => {
    Post(`users/addRole`, value)
    .then((res) => {
        if (!res.success) {
            showTost(res.message);
            return;
        }
        showTost(res.message);
        navigate('/RoleMaster');
    }).catch((err) => {
        showTost(`Error saving Role data: ${err.message}`, "warning");
    });
};
  return (
    <div className="container-fluid">
      <Formik
        enableReinitialize={true}
        onSubmit={handleSubmit}
        initialValues={initValue}
        validationSchema={addDataSchema}
      >
        {({
          handleSubmit,
          handleChange,
          values,
          touched,
          errors,
          setFieldValue
        }) => {
          return (
            <>
              <div className="row pt-2 pb-2">
                <div className="col-sm-10">
                  <h4 className="page-title">{params.id ? "Edit Role" : "Add Role"}</h4>
                </div>
                <div className="col-sm-2">
                  <div className="btn-group float-sm-right">
                    <Link to={"/RoleMaster"}><button type="button" className="btn btn-light waves-effect waves-light"><i className="fa fa-bars mr-1" /> View Role</button></Link>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12">
                  <div className="card">
                    <div className="card-body">
                      <Form id="textmasterform"
                        onSubmit={handleSubmit}
                        onChange={handleChange}>
                        <div className="row">
                          <div className="col-12 col-lg-4 col-xl-4">
                            <div className="form-group">
                              <label className="col-form-label"><i className="fa fa-asterisk" style={{ color: 'red', fontSize: '11px' }} /> Name:</label>
                              <Field value={values.name} type="text" name="name" className={`form-control ${touched.name && errors.name ? "is-invalid" : ""}`} />
                              <ErrorMessage name="name" component="span" className="error" />
                            </div>
                          </div>
                          <div className="col-12">
                            <Button type="reset" className="btn btn-danger waves-effect waves-light float-right ml-2" onClick={() => clearData()}>Cancel</Button>
                            <Button type="submit" className={(params.id ? "btn-success" : "btn-light") + " btn waves-effect waves-light float-right"}>{params.id ? "Update" : "Add New"}</Button>
                          </div>
                        </div>
                      </Form>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )
        }}
      </Formik>
    </div>
  )
}

export default RoleAdd
