import React, { useEffect, useState, useRef } from 'react';
import { Formik, Form, Field, ErrorMessage } from "formik";
import { Post, Get, FileUpload } from '../../common/ajax';
import { immediateToast } from 'izitoast-react';
import { Modal, Button } from "react-bootstrap";
import * as Yup from "yup";
import './chat.css';
import { useParams } from 'react-router-dom';
import moment from "moment";

const addDataSchema = Yup.object().shape({
    Message: Yup.string().required("Message field is required"),
});
const Chat = () => {
    let params = useParams();
    const inputRef = useRef(null);
    const [pdf, setpdf] = useState(null);
    const [pdfName, setPdfName] = useState("");
    const [error, setError] = useState("");
    const [data, setData] = useState([]);
    const [name, setname] = useState("");
    const [mobile, setmobile] = useState("");
    const [image, setimage] = useState(process.env.REACT_APP_APIURL + "static/user.png");
    const [formkey, setFormKey] = useState(Math.random() * 1000000);
    const [remainingTime, setremainingTime] = useState("");
    const [totalTime, settotalTime] = useState("");
    const [endTime, setendTime] = useState(0);
    const bottomRef = useRef(null);

    let role = JSON.parse(window.localStorage.getItem("AdminRole"));

    useEffect(() => {
        if (totalTime != "") {

            var tInterval = setInterval(() => {
                var now = new Date();

                if (now < endTime) {
                    var diff = endTime - now;
                    setremainingTime(getTimes(diff));
                }
                else {
                    clearInterval(tInterval);
                    alert("Appointment time is over.");
                    window.location.replace('/dashboard');
                }
            }, 1000);

        }
    }, [totalTime, remainingTime]);

    useEffect(() => {
        if (totalTime != "") {
            setInterval(async () => {
                //await getPost();
            }, 5000);
        }
    })

    const scrollToBottom = () => {
        bottomRef.current?.scrollIntoView({ behavior: "smooth" });
    };

    //formatting total and remaining time
    const getTimes = (diff) => {
        let ss = Math.floor(diff / 1000) % 60;
        let mm = Math.floor(diff / 1000 / 60) % 60;
        let hh = Math.floor(diff / 1000 / 60 / 60);
        ss = ss.toString().length > 1 ? ss : "0" + ss;
        mm = mm.toString().length > 1 ? mm : "0" + mm;
        hh = hh.toString().length > 1 ? hh : "0" + hh;
        return hh + ":" + mm + ":" + ss;
    };

    const setfile = async (val) => {
        const file = val.target.files[0];
        if (file.size > 5 * 1024 * 1024) {
            setError("File size exceeds 5 MB limit.");
            setpdf(null);
            setPdfName("");
            return;
        }
        if (file) {
            setpdf(file);
            setPdfName(file.name);
            setError("");
        }
    };
    const showTost = (msg, theme) => {
        theme = theme || "info";
        immediateToast(theme, {
            message: msg,
            timeout: 3000,
            position: "topRight"
        });
    }
    const [initValue, setValue] = useState({
        AppointmentId: params.id,
        Message: "",
        addedBy: "Doctor",
        file: "",
    });
    useEffect(() => {
        getPost();
        getById();
    }, []);
    const clear = () => {
        setValue({
            AppointmentId: params.id,
            Message: "",
            addedBy: "Doctor"
        });
        setpdf(null);
        setError("");
    }
    const getById = async () => {
        Get(`appointment/getAppointmentById/${params.id}`)
            .then((res) => {
                var data = res.data.detail;
                if (role != "Doctor") {
                    setname(data.doctorId?.firstName + " " + data.doctorId?.middleName + " " + data.doctorId?.lastName);
                    setmobile(data.doctorId?.mobileNo);
                    setimage(process.env.REACT_APP_APIURL + "static/" + data.doctorId?.image);
                }
                else {
                    setname(data.patientId?.firstName + " " + data.patientId?.middleName + " " + data.patientId?.lastName);
                    setmobile(data.patientId?.mobileNo);
                    setimage(process.env.REACT_APP_APIURL + "static/" + data.patientId?.image);
                }
                var curtDate = new Date();
                var startDt = new Date(res.data.startTime);
                var endDt = new Date(res.data.endTime);
                setendTime(endDt);
                let diff = endDt - startDt;

                settotalTime(getTimes(diff));
                settotalTime(getTimes(diff));
                var nDate = startDt.setMinutes(startDt.getMinutes() - 5);
                startDt = new Date(nDate);

                if (curtDate > startDt && curtDate < endDt) {

                } else {
                    alert("Invalid date time slot");
                    window.location.replace('/dashboard');
                }
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
    }
    const getPost = async () => {
        Get(`appointment/GetChatList/${params.id}`)
            .then((res) => {
                var item = res.data.map((x) => {
                    x.createdAt = moment(x.createdAt).format('DD/MM/YYYY, hh:mm:ss a');
                    return x;
                })
                setData(res.data);
                setTimeout(() => {
                    scrollToBottom();
                }, 200);
            })
            .catch((err) => {
                showTost("Something went wrong please try after sometime");
            });
    }

    const handleSubmit = async (val) => {
        let formdata = new FormData();
        formdata.append("AppointmentId", val.AppointmentId);
        formdata.append("addedBy", val.addedBy);
        formdata.append("file", pdf);
        formdata.append("Message", val.Message);
        FileUpload(`appointment/saveChat`, formdata)
            .then((res) => {
                if (res.success == true) {
                    clear();
                    getPost();
                    setFormKey(Math.random() * 1000000);
                }
            })
            .catch((err) => {
                showTost("Something went wrong please try after sometime");
            });
    };

    return (
        <div className="container-fluid">
            <div className="row py-2 align-items-center">
                <div className="col-sm-10">
                    <h4 className="page-title">Chat</h4>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-12">
                    <div className="card">
                        <div className="card-body">
                            <main className="chat-main-wrapper p-0">
                                <div className="chat-header">
                                    <div className="chat-header-contact-master">
                                        <img className="chat-header-contact-profile-pic" src={image} />
                                        <div className="chat-header-contact-name">
                                            <div>
                                                {name}
                                                {/* <div className="chat-header-contact-connection-display">
                                                {mobile}
                                            </div> */}
                                            </div>
                                            <div className="videoBottomdisplay">
                                                <label className="chattimeLabel" >
                                                    <i className="fa fa-clock" style={{ marginRight: "10px" }}></i>{remainingTime}/{totalTime}
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="chat-box">
                                    <div className="chat-conversation-wrapper" style={{ maxHeight: "345px" }}>
                                        {data.map(x => (<>
                                            {x.addedBy != role ?
                                                <div className="contact-message-wrapper">
                                                    <div className="contact-message-box-style" />
                                                    <div className="contact-message-box">
                                                        <div className="contact-message-box-p">
                                                            {x.Document &&
                                                                <>
                                                                    <a target="_blank" className="py-1 px-2 rounded" style={{ backgroundColor: '#fff', color: 'grey' }}
                                                                        href={`${process.env.REACT_APP_APIURL}static/${x.Document}`}>
                                                                        <i class="fa fa-file mr-2" style={{ color: '#3523df' }}></i>{x.Document}
                                                                    </a>
                                                                </>
                                                            }
                                                            <div className="message-box-p mt-2" style={{ color: "black" }}>
                                                                {x.Message}
                                                                <label class="chatTime">{x.createdAt}</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                :
                                                <div className="message-wrapper">
                                                    <div className="message-box">
                                                        {x.Document &&
                                                            <>
                                                                <a target="_blank" className="py-1 px-2 rounded" style={{ backgroundColor: '#fff', color: 'grey' }}
                                                                    href={`${process.env.REACT_APP_APIURL}static/${x.Document}`}>
                                                                    <i class="fa fa-file mr-2" style={{ color: '#3523df' }}></i>{x.Document}
                                                                </a>
                                                            </>
                                                        }
                                                        <div className="message-box-p mt-2">{x.Message}
                                                            <label class="chatTime">{x.createdAt}</label>
                                                        </div>
                                                    </div>
                                                    <div className="message-box-style" />
                                                </div>
                                            }
                                        </>))}
                                        <div className='bottomMsg' ref={bottomRef} />
                                    </div>
                                    <Formik
                                        enableReinitialize={true}
                                        onSubmit={handleSubmit}
                                        initialValues={initValue}
                                        validationSchema={addDataSchema}
                                        key={formkey}
                                    >
                                        {({
                                            handleSubmit,
                                            handleChange,
                                            values,
                                            touched,
                                            errors,
                                            setFieldValue,
                                        }) => {
                                            return (
                                                <Form id="textmasterform"
                                                    onSubmit={handleSubmit}
                                                    onChange={handleChange}>
                                                    <div className="chat-user-message-wrapper">
                                                        <div className="">
                                                            <span className="btn btn-sm mr-2" style={{ backgroundColor: '#2391df', fontSize: '17px', color: '#fff' }}
                                                                onClick={() => { inputRef.current.click(); }}
                                                            >
                                                                <i className="fa fa-paperclip" />
                                                            </span>
                                                            <input
                                                                style={{ display: "none" }}
                                                                ref={inputRef}
                                                                type="file"
                                                                onChange={setfile}
                                                            />
                                                            {pdf && <label className="mt-2">{pdfName}</label>}
                                                            {error && <p style={{ color: "red" }}>{error}</p>}
                                                        </div>
                                                        <div className="chat-user-message-form-wrapper">
                                                            <Field autoFocus value={values.Message} type="text" name="Message" id="user-message-form" className={`form-control ${touched.Message && errors.Message ? "is-invalid" : ""}`} placeholder="Type your message here" />
                                                        </div>
                                                        <button className="btnborder" type="submit"><i className="fa fa-paper-plane" id="send-message" style={{ color: '#2391df' }} /></button>
                                                    </div>
                                                    <ErrorMessage name="name" component="span" className="chat-user-message-wrapper error" />
                                                </Form>
                                            );
                                        }}
                                    </Formik>
                                </div>
                            </main>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Chat
