import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Post, Get } from '../../common/ajax';
import { immediateToast } from 'izitoast-react';
import Dropdown from 'react-bootstrap/Dropdown';
import DialogBox from "../../common/DialogBox";
const Role = () => {
  const [data, setData] = useState([]);
  const [showDialogBox, setDialogBox] = useState(false);
  const [dialogMessage, setdialogMessage] = useState("");
  const [rowId, setRowId] = useState("");
  const [rowStatus, setrowStatus] = useState(false);
  const showTost = (msg, theme) => {
    theme = theme || "info";
    immediateToast(theme, {
      message: msg,
      timeout: 3000,
      position: "topRight"
    });
  }
  useEffect(() => {
    getPost(true);
  }, []);
  const getPost = async (val) => {
    await Post(`users/getAllRole`, { active: val })
      .then(async (res) => {
        if (res.data) {
          await res.data.map((row, i) => {
            row.name = row.name;
            row.is_active = row.is_active ? "Active" : "InActive";
          })
          setData(res.data);
        } else {
          showTost(res.message, "error")
        }
      }).catch((err) => {
        showTost("Error in getting Role List" + err.message, "warning");
      })
  }
  const showStatusDiaglog = (rowId, status) => {
    setRowId(rowId);
    setrowStatus(!status);
    var val = status === true ? "inactivate" : "activate";
    setdialogMessage("Do you want to " + val + " Role?");
    setDialogBox(true);
  }
  const changeStatus = async (id, active) => {
    Post(`masters/removeRole`, { id: rowId, active: rowStatus })
      .then((res) => {
        if (res.success) {
          showTost(res.message, "success");
          getPost(!rowStatus);
          setDialogBox(false);
        }
        else {
          showTost(res.message, "warning");
        }
      })
      .catch((err) => {
        showTost("Error in changing status of Role" + err.message, "warning");
      });
  }
  return (
    <div className="container-fluid">
      <div className="row py-2 align-items-center">
        <div className="col-lg-7 col-md-6 col-sm-5 mb-2 mb-sm-0">
          <h4 className="page-title">Role Master</h4>
        </div>
        <div className="col-lg-5 col-md-6 col-sm-7">
          <div className='d-flex justify-content-sm-end align-items-center flex-wrap'>
            <div className="btn-group mr-2 mb-2 mb-md-0">
              <select className='form-control' onChange={(e) => { getPost(e.target.value); setrowStatus(e.target.value) }}>
                <option value="true">Active</option>
                <option value="false">InActive</option>
              </select>
            </div>
            <div className="btn-group mb-2 mb-md-0">
              <Link to={"/RoleAdd"}><button type="button" className="btn btn-light waves-effect waves-light"><i className="fa fa-plus mr-1" /> Add Role</button></Link>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <div className="card">
            <div className="card-body">
              <div className="table-responsive">
                <table id="default-datatable" className="table table-bordered">
                  <thead>
                    <tr>
                      <th></th>
                      <th>Name</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.map(m => <>
                      <tr key={m._id}>
                        <td>
                          <Dropdown>
                            <Dropdown.Toggle variant="success" size="sm" drop="end" id="dropdown-basic">
                              <i className='fa fa-cog'></i>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              <Dropdown.Item href={`/RoleAdd/${m._id}`}>Edit</Dropdown.Item>
                              <Dropdown.Item onClick={() => showStatusDiaglog(m._id, m.is_active === "Active")} href="#" >
                                Set As {m.is_active === "Active" ? "InActive" : "Active"}
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </td>
                        <td>{m.name}</td>
                        <td>{m.is_active === "Active" ? "Active" : "InActive"}</td>
                      </tr>
                    </>)}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <DialogBox show={showDialogBox} okClick={changeStatus} close={() => { setDialogBox(false); setRowId("") }} message={dialogMessage}></DialogBox>
    </div>
  )
}

export default Role
