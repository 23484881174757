const FileUpload = (url, formdata) => {
  return new Promise((resolve, reject) => {
    var myHeaders = new Headers();
    var myHeaders = {
      'Authorization': `Bearer ${JSON.parse(window.localStorage.getItem("AdminToken"))}`,
      'role': `${JSON.parse(window.localStorage.getItem("AdminRole"))}`,
    };
    fetch(process.env.REACT_APP_APIURL + "api/" + url, {
      method: "POST",
      body: formdata,
      redirect: 'follow',
      headers: myHeaders,
    })
      .then(response => response.json())
      .then(json => {
        if (json.message) {
          if (json.message == "Authorization Failure!") {
            logout();
          }
        }
        resolve(json);
      })
      .catch(error => {
        reject(error);
      });
  });
}

const EditFileUpload = (url, formdata) => {
  var myHeaders = new Headers();
  var myHeaders = {
    'Authorization': `Bearer ${JSON.parse(window.localStorage.getItem("AdminToken"))}`,
    'role': `${JSON.parse(window.localStorage.getItem("AdminRole"))}`,
  };

  return new Promise((resolve, reject) => {
    fetch(process.env.REACT_APP_APIURL + "api/" + url, {
      method: "PUT",
      body: formdata,
      headers: myHeaders,
      redirect: 'follow'
    })
      .then(response => response.json())
      .then(json => {
        if (json.message) {
          if (json.message == "Authorization Failure!") {
            logout();
          }
        }
        resolve(json);
      })
      .catch(error => {
        reject(error);
      });
  });
}

const Post = (url, data) => {

  return new Promise((resolve, reject) => {
    try {
      var myHeaders = new Headers();
      var myHeaders = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${JSON.parse(window.localStorage.getItem("AdminToken"))}`,
        'role': `${JSON.parse(window.localStorage.getItem("AdminRole"))}`,
      };

      fetch(process.env.REACT_APP_APIURL + "api/" + url, {
        method: "POST",
        body: JSON.stringify(data),
        headers: myHeaders,
      })
        .then(response => response.json())

        .then(json => {
          if (json.message) {
            if (json.message == "Authorization Failure!") {
              logout();
            }
          }

          resolve(json);
        })
        .catch(error => {

          reject(error);
        });
    } catch (error) {
      reject(error);
    }
  });
}

const logout = () => {
  window.localStorage.removeItem("AdminToken");
  window.localStorage.removeItem("AdminUser");
  window.localStorage.removeItem("Email");
  window.localStorage.removeItem("AdminUsername");
  window.localStorage.removeItem("AdminRole");
  window.localStorage.removeItem("PasswordModalShown");
  window.localStorage.removeItem("ExpiryTime");
  window.location.reload();
  window.location.replace('/index');
}
const File = (url, data, fileName) => {
  return new Promise((resolve, reject) => {
    try {
      var myHeaders = new Headers();
      var myHeaders = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${JSON.parse(window.localStorage.getItem("AdminToken"))}`,
        'role': `${JSON.parse(window.localStorage.getItem("AdminRole"))}`,
      };

      fetch(process.env.REACT_APP_APIURL + "api/" + url, {
        method: "POST",
        body: JSON.stringify(data),
        headers: myHeaders,
      })
        .then(response => {
          response.blob().then(blob => {
            // Creating new object of PDF file
            const fileURL = window.URL.createObjectURL(blob);
            // Setting various property values
            // let alink = document.createElement('a');
            // alink.href = fileURL;
            // alink.target="_blank"
            // alink.download = fileName;
            // alink.click();
            window.open(fileURL, "_blank");
            resolve("");
          })
        })
        .catch(error => {
          reject(error);
        });
    } catch (error) {
      reject(error);
    }
  });
}

const Get = (url, data) => {

  return new Promise((resolve, reject) => {
    var myHeaders = new Headers();
    var myHeaders = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${JSON.parse(window.localStorage.getItem("AdminToken"))}`,
      'role': `${JSON.parse(window.localStorage.getItem("AdminRole"))}`,
    };
    fetch(process.env.REACT_APP_APIURL + "api/" + url, {
      method: "GET",
      body: JSON.stringify(data),
      headers: myHeaders,
    })
      .then(response => response.json())
      .then(json => {
        if (json.message) {
          if (json.message == "Authorization Failure!") {
            logout();
          }
        }

        resolve(json);
      })
      .catch(error => {
        reject(error);
      });
  });
}


const GetCountryList = () => {

  return new Promise((resolve, reject) => {
    var myHeaders = new Headers();
    var myHeaders = {
      'Content-Type': 'application/json',
      'X-CSCAPI-KEY': process.env.REACT_APP_CITYAPI,
    };
    fetch("https://api.countrystatecity.in/v1/countries", {
      method: "GET",
      headers: myHeaders,
      redirect: 'follow'
    })
      .then(response => response.json())
      .then(json => {
        resolve(json);
      })
      .catch(error => {
        reject(error);
      });
  });
}

const GetStateList = (country) => {

  return new Promise((resolve, reject) => {
    var myHeaders = new Headers();
    var myHeaders = {
      'Content-Type': 'application/json',
      'X-CSCAPI-KEY': process.env.REACT_APP_CITYAPI,
    };
    fetch("https://api.countrystatecity.in/v1/countries/" + country + "/states", {
      method: "GET",
      headers: myHeaders,
      redirect: 'follow'
    })
      .then(response => response.json())
      .then(json => {
        resolve(json);
      })
      .catch(error => {
        reject(error);
      });
  });
}

const GetCityList = (country, state) => {

  return new Promise((resolve, reject) => {
    var myHeaders = new Headers();
    var myHeaders = {
      'Content-Type': 'application/json',
      'X-CSCAPI-KEY': process.env.REACT_APP_CITYAPI,
    };
    fetch("https://api.countrystatecity.in/v1/countries/" + country + "/states/" + state + "/cities", {
      method: "GET",
      headers: myHeaders,
      redirect: 'follow'
    })
      .then(response => response.json())
      .then(json => {
        resolve(json);
      })
      .catch(error => {
        reject(error);
      });
  });
}

export {
  Post,
  FileUpload,
  EditFileUpload,
  File,
  Get,
  GetCountryList,
  GetStateList,
  GetCityList
};