import React, { useEffect, useState } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { Formik, Field, ErrorMessage } from "formik";
import { Post, Get, FileUpload, GetCountryList, GetStateList, GetCityList } from '../../common/ajax';
import { Form, Button } from "react-bootstrap";
import { immediateToast } from 'izitoast-react';
import * as Yup from "yup";
import { serialize } from 'object-to-formdata';
import moment from "moment";
import Swal from "sweetalert2";

const requiredGenderErrorMessage = "Gender Name field is required";
const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
const addDataSchema = Yup.object().shape({
    firstName: Yup.string().min(2, 'Too Short!')
        .max(50, 'Too Long!')
        .matches(/^[A-Za-z]*$/, { message: "Please enter valid first name.", excludeEmptyString: false })
        .required("First name field is required"),
    middleName: Yup.string().matches(/^[A-Za-z]*$/, { message: "Please enter valid first name.", excludeEmptyString: false }),
    lastName: Yup.string().min(2, 'Too Short!')
        .max(50, 'Too Long!')
        .matches(/^[A-Za-z]*$/, { message: "Please enter valid Last name.", excludeEmptyString: false })
        .required("Last name field is required"),
    gender: Yup.string().required(requiredGenderErrorMessage),
    email: Yup.string().email('Invalid email address').required("Email field is required"),
    mobileNo: Yup.string().min(10).max(10).matches(phoneRegExp, { message: "Please enter valid number.", excludeEmptyString: false }).required("Phone Number field is required"),
    address: Yup.string().required("Address field is required"),
    city: Yup.string().required("City field is required"),
    state: Yup.string().required("State field is required"),
    country: Yup.string().required("Country field is required")
});

const PatientsAdd = () => {
    let params = useParams();
    const navigate = useNavigate();
    const [countryList, setcountryList] = useState([]);
    const [stateList, setstateList] = useState([]);
    const [selectedState, setselectedState] = useState("");
    const [cityList, setcityList] = useState([]);
    const [selectedCity, setselectedCity] = useState("");
    const [selectedDocFile, setselectedDocFile] = useState(null);
    const [docUrl, setdocUrl] = useState([]);
    const [newDocs, setnewDocs] = useState([]);
    const [imgUrl, setimgUrl] = useState("");
    const [selectedFile, setselectedFile] = useState(null);
    const [imgName, setimgName] = useState("");

    const [initValue, setValue] = useState({
        id: "",
        firstName: "",
        middleName: "",
        lastName: "",
        gender: "",
        BirthDate: "",
        email: "",
        mobileNo: "",
        address: "",
        address2: "",
        city: "",
        state: "",
        country: "",
        zipcode: "",
        phoneCode: "",
        medicalHistory: "",
        image: "",
        file: null,
        is_active: true,
    });

    const clearData = () => {
        Swal.fire({
            title: "All unsaved data will be lost. Do you want to proceed?",
            showCancelButton: true,
            confirmButtonText: "Yes",
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                navigate('/PatientsMaster');
            } else if (result.isDenied) {
                //Swal.fire("Changes are not saved", "", "info");
            }
        });


    }
    useEffect(() => {
        getCountry();
        if (params.id) {
            getPost();
        }
    }, []);

    useEffect(() => {
        setselectedState(initValue.state);
        GetCitys(initValue.state);
    }, [stateList.length])

    useEffect(() => {
        setselectedCity(initValue.city);
    }, [cityList.length])

    useEffect(() => {

        fetch("https://ipapi.co/json/")
            .then(response => response.json())
            .then(data => {
                //var geo = geoip.lookup(data.ip);
                //console.log(data);
                setValue(item => ({ ...item, country: data.country_name, phoneCode: data.country_calling_code.replace("+", ""), state: data.region, city: data.city, stateName: data.state }));
                GetStates(data.country_name);
            })
            .catch(error => {
                console.error("Error fetching IP address:", error);
            });

    }, [countryList])

    const getPost = async () => {

        const result = Get(`patients/getPatientById/${params.id}`)
            .then((res) => {
                if (res.data) {
                    if (res.data.image) {
                        setimgUrl(process.env.REACT_APP_APIURL + "static/" + res.data.image);
                        setselectedFile(null);
                    }
                    setnewDocs(res.data.document);
                    setdocUrl(res.data.document);
                    setresponcevalue(res.data);
                }
            })
            .catch((err) => {
                showTost("Error getting in patient details" + err.message, "warning");
            });
    }

    const getCountry = async () => {
        await GetCountryList().then((res) => { setcountryList(res.sort((a, b) => a.name > b.name ? 1 : -1)); })
    }
    const GetStates = async (value) => {
        setValue(item => ({ ...item, country: value }));
        var selectedCountry = countryList.filter(country => country.name === value);
        if (selectedCountry.length > 0) {
            await GetStateList(selectedCountry[0].iso2).then((res) => { setstateList(res.sort((a, b) => a.name > b.name ? 1 : -1)); })
        }
    }

    const GetCitys = async (value) => {
        if (value) {
            setValue(item => ({ ...item, state: value }));
            setselectedState(value);
            var selectedCountry = countryList.filter(country => country.name === initValue.country);
            var selectedState = stateList.filter(state => state.name === value);
            if (selectedCountry.length > 0) {
                await GetCityList(selectedCountry[0].iso2, selectedState[0].iso2).then((res) => { setcityList(res.sort((a, b) => a.name > b.name ? 1 : -1)); })
            }
        }
    }

    const setresponcevalue = async (res) => {
        setValue({
            id: params.id,
            firstName: res.firstName,
            middleName: res.middleName,
            lastName: res.lastName,
            gender: res.gender,
            BirthDate: res.BirthDate ? moment(res.BirthDate).format("YYYY-MM-DD") : "",
            // BirthDate: moment(res.BirthDate).format("YYYY-MM-DD"),
            email: res.email,
            mobileNo: res.mobileNo,
            address: res.address,
            address2: res.address2,
            country: res.country,
            state: res.state,
            city: res.city,
            zipcode: res.zipcode,
            phoneCode: res.phoneCode,
            medicalHistory: res.medicalHistory,
            image: res.image,
        });
    }

    const showTost = (msg, theme) => {
        theme = theme || "info";
        immediateToast(theme, {
            message: msg,
            timeout: 3000,
            position: "topRight"
        });
    }
    const removeFile = async (name, type) => {
        if (selectedFile && type === "img") {
            document.getElementById("uploadFile").files = null;
            setimgUrl(null);
            setimgName("");
            setselectedFile(null);
            return;
        }
        if (type === "doc") {
            var idx = -1;

            const dt = new DataTransfer();
            if (selectedDocFile) {

                for (var i = 0; i < selectedDocFile.length; i++) {
                    if (name !== selectedDocFile[i].name) {
                        dt.items.add(selectedDocFile[i]);
                    }
                    else {
                        idx = i;
                    }
                }
            }
            var nameIdx = docUrl.indexOf(name);
            var dURL = docUrl;
            dURL.splice(nameIdx, 1);
            setdocUrl(dURL);

            if (idx !== -1) {
                setselectedDocFile(dt.files);
                return;
            }

        }
        Post(`patients/removeFile`, { id: params.id, type: type, name: name })
            .then((res) => {
                if (res.success == true) {
                    showTost(res.message, "success");
                    if (type === "img") {
                        setimgUrl("");
                        setselectedFile(null);
                    }
                    else {
                        window.location.reload();
                    }
                }
                else {
                    showTost(res.message, "warning");
                }
            })
            .catch((err) => {
                showTost("Error getting when patient remove image" + err.message, "warning");
            });
    }

    const handleSubmit = async (value) => {
        let val = { ...value };
        val.file = selectedFile;
        val.availableDoc = newDocs;
        val.city = selectedCity;
        const formData = serialize(val, { dotsForObjectNotation: true });
        if (selectedDocFile) {
            for (var i = 0; i < selectedDocFile.length; i++) {
                formData.append("document[]", selectedDocFile[i]);
            }
        }
        FileUpload(`patients/addPatients`, formData)
            .then((res) => {
                if (res.success == false) {
                    showTost(res.message);
                    return false;
                }
                else {
                    showTost(res.message);
                    navigate('/PatientsMaster');
                }
            })
            .catch((err) => {
                showTost("Error getting when patient data save." + err.message, "warning");
            });
    };
    const handletextClick = () => {
        document.getElementById("uploadFile").click();
    }

    const handletextChange = () => {
        var file = document.getElementById("uploadFile").files[0];
        if (file) {
            var name = file.name;
            setimgUrl(URL.createObjectURL(file));
            setselectedFile(file);
        }
    }

    const handledocClick = () => {
        document.getElementById("uploadDoc").click();
    }

    const handledocChange = () => {
        var files = document.getElementById("uploadDoc").files;

        setdocUrl([]);
        if (files) {
            const dt = new DataTransfer();
            var newFile = [];
            if (selectedDocFile) {
                for (var i = 0; i < selectedDocFile.length; i++) {
                    dt.items.add(selectedDocFile[i]);
                    newFile.push(selectedDocFile[i].name)
                }
                setselectedDocFile(dt.files);
            }
            else {
                setselectedDocFile(files);
            }
            for (var i = 0; i < files.length; i++) {
                dt.items.add(files[i]);
                newFile.push(files[i].name)
            }

            var docs = [];
            for (var i = 0; i < dt.files.length; i++) {
                docs.push(dt.files[i].name);
            }
            for (var i = 0; i < docUrl.length; i++) {
                docs.push(docUrl[i]);
            }
            setdocUrl(docs);

        }
    }
    return (
        <div className="container-fluid">
            <Formik
                enableReinitialize={true}
                onSubmit={handleSubmit}
                initialValues={initValue}
                validationSchema={addDataSchema}
            >
                {({
                    handleSubmit,
                    handleChange,
                    values,
                    touched,
                    errors,
                    setFieldValue,
                }) => {
                    return (
                        <>
                            <div className="row pt-2 pb-2">
                                <div className="col-sm-10">
                                    <h4 className="page-title">{params.id ? "Edit Patients" : "Add Patients"} </h4>
                                </div>
                                {/* <div className="col-sm-2">
                                    <div className="btn-group float-sm-right">
                                        <Link to={"/PatientsMaster"}><button type="button" className="btn btn-light waves-effect waves-light"><i className="fa fa-bars mr-1" /> View Patients</button></Link>
                                    </div>
                                </div> */}
                            </div>
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <Form id="textmasterform"
                                                onSubmit={handleSubmit}
                                                onChange={handleChange}>
                                                <div className="row">
                                                    <div className="col-12 col-lg-4 col-xl-4">
                                                        <div className="form-group">
                                                            <label className="col-form-label"><i className="fa fa-asterisk" style={{ color: 'red', fontSize: '11px' }} /> First Name:</label>
                                                            <Field value={values.firstName} type="text" name="firstName" className={`form-control ${touched.firstName && errors.firstName ? "is-invalid" : ""}`} />
                                                            <ErrorMessage name="firstName" component="span" className="error" />
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-lg-4 col-xl-4">
                                                        <div className="form-group">
                                                            <label className="col-form-label"> Middle Name :</label>
                                                            <Field value={values.middleName} type="text" name="middleName" className={`form-control ${touched.middleName && errors.middleName ? "is-invalid" : ""}`} />
                                                            <ErrorMessage name="middleName" component="span" className="error" />
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-lg-4 col-xl-4">
                                                        <div className="form-group">
                                                            <label className="col-form-label"><i className="fa fa-asterisk" style={{ color: 'red', fontSize: '11px' }} /> Last Name:</label>
                                                            <Field value={values.lastName} type="text" name="lastName" className={`form-control ${touched.lastName && errors.lastName ? "is-invalid" : ""}`} />
                                                            <ErrorMessage name="lastName" component="span" className="error" />
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-lg-4 col-xl-4">
                                                        <div className="form-group">
                                                            <label className="col-form-label"><i className="fa fa-asterisk" style={{ color: 'red', fontSize: '11px' }} /> Gender:</label>
                                                            <Form.Control
                                                                className="input_focus"
                                                                as="select"
                                                                name="gender"
                                                                value={values.gender}
                                                                isInvalid={!!touched.gender && !!errors.gender}
                                                            >
                                                                <option value="">Select</option>
                                                                <option value="male">Male</option>
                                                                <option value="female">Female</option>
                                                                <option value="I do not want to specify">I do not want to specify</option>
                                                            </Form.Control>
                                                            <ErrorMessage name="gender" component="span" className="error" />
                                                        </div>
                                                    </div>
                                                    <div className='col-12 col-lg-4 col-xl-4'>
                                                        <label className="col-form-label">Birth Date:</label>
                                                        <Field value={values.BirthDate} type="date" name="BirthDate" className={`form-control ${touched.BirthDate && errors.BirthDate ? "is-invalid" : ""}`} />
                                                        <ErrorMessage name="BirthDate" component="span" className="error" />
                                                    </div>
                                                    <div className="col-12 col-lg-4 col-xl-4">
                                                        <div className="form-group">
                                                            <label className="col-form-label"><i className="fa fa-asterisk" style={{ color: 'red', fontSize: '11px' }} /> Email:</label>
                                                            <Field value={values.email} type="email" name="email" className={`form-control ${touched.email && errors.email ? "is-invalid" : ""}`} />
                                                            <ErrorMessage name="email" component="span" className="error" />
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-lg-4 col-xl-4">
                                                        <div className="form-group">
                                                            <label className="col-form-label"><i className="fa fa-asterisk" style={{ color: 'red', fontSize: '11px' }} /> Mobile:</label>
                                                            <div className='row'>
                                                                <div className='col-4'>
                                                                    <select className='form-control' value={values.phoneCode} onChange={(e) => { setValue(item => ({ ...item, phoneCode: e.target.value })); }}>
                                                                        {countryList && countryList.map((country, i) => {
                                                                            return (
                                                                                <>
                                                                                    <option key={Math.random()} value={country.phonecode}>{`${country.phonecode} ( ${country.iso2} )`} </option>
                                                                                </>
                                                                            )
                                                                        })}
                                                                    </select>
                                                                </div>
                                                                <div className='col-8'>
                                                                    <Field value={values.mobileNo} type="text" name="mobileNo" className={`form-control ${touched.mobileNo && errors.mobileNo ? "is-invalid" : ""}`} />
                                                                </div>
                                                            </div>
                                                            <ErrorMessage name="mobileNo" component="span" className="error" />
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-lg-4 col-xl-4">
                                                        <div className="form-group">
                                                            <label className="col-form-label"><i className="fa fa-asterisk" style={{ color: 'red', fontSize: '11px' }} /> Address1:</label>
                                                            <Field value={values.address} type="text" name="address" className={`form-control ${touched.address && errors.address ? "is-invalid" : ""}`} />
                                                            <ErrorMessage name="address" component="span" className="error" />
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-lg-4 col-xl-4">
                                                        <div className="form-group">
                                                            <label className="col-form-label">Address2:</label>
                                                            <Field value={values.address2} type="text" name="address2" className={`form-control`} />
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-lg-4 col-xl-4">
                                                        <div className="form-group">
                                                            <label className="col-form-label"><i className="fa fa-asterisk" style={{ color: 'red', fontSize: '11px' }} />Country:</label>
                                                            {/* <Field value={values.country} type="text" name="country" className={`form-control`} /> */}
                                                            <select className='form-control' value={values.country} onChange={(e) => { GetStates(e.target.value) }} name="country">
                                                                {countryList && countryList.map((country, i) => {
                                                                    return (
                                                                        <>
                                                                            <option key={Math.random()} value={country.name}>{country.name} </option>
                                                                        </>
                                                                    )
                                                                })}
                                                            </select>
                                                            <ErrorMessage name="country" component="span" className="error" />
                                                        </div>
                                                    </div>

                                                    <div className="col-12 col-lg-4 col-xl-4">
                                                        <div className="form-group">
                                                            <label className="col-form-label"><i className="fa fa-asterisk" style={{ color: 'red', fontSize: '11px' }} />State:</label>
                                                            {/* <Field value={values.state} type="text" name="state" className={`form-control`} /> */}
                                                            <select className='form-control' value={selectedState} onChange={(e) => { GetCitys(e.target.value) }} name="state">
                                                                {stateList && stateList.map((state, i) => {
                                                                    return (
                                                                        <>
                                                                            <option key={Math.random()} value={state.name}>{state.name} </option>
                                                                        </>
                                                                    )
                                                                })}
                                                            </select>
                                                            <ErrorMessage name="state" component="span" className="error" />
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-lg-4 col-xl-4">
                                                        <div className="form-group">
                                                            <label className="col-form-label"><i className="fa fa-asterisk" style={{ color: 'red', fontSize: '11px' }} />City:</label>
                                                            {/* <Field value={values.city} type="text" name="city" className={`form-control`} /> */}
                                                            <select className='form-control' value={selectedCity} onChange={(e) => { setselectedCity(e.target.value); }} name="city">
                                                                {cityList && cityList.map((city, i) => {
                                                                    return (
                                                                        <>
                                                                            <option key={Math.random()} value={city.name}>{city.name} </option>
                                                                        </>
                                                                    )
                                                                })}
                                                            </select>
                                                            <ErrorMessage name="city" component="span" className="error" />
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-lg-4 col-xl-4">
                                                        <div className="form-group">
                                                            <label className="col-form-label">ZipCode:</label>
                                                            <Field value={values.zipcode} type="text" name="zipcode" className={`form-control`} />
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-lg-4 col-xl-4">
                                                        <div className="form-group">
                                                            <label className="col-form-label">Medical History:</label>
                                                            <Field value={values.medicalHistory} type="text" name="medicalHistory" className={`form-control`} />
                                                            <ErrorMessage name="medicalHistory" component="span" className="error" />
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-lg-6 col-xl-6 row">
                                                        <input id="uploadFile" type="file" name="file" accept="application/.jpg,.jpeg,.png,.gif" onChange={handletextChange} style={{ "display": "none" }} />
                                                        <div className="uploadBlock mb-4 mt-3">
                                                            <a href="#" className="uploadLink" value="Upload File" onClick={handletextClick} style={{ marginTop: "-20px" }} >
                                                                <em className="fa fa-arrow-up" ></em><br></br>Upload Profile Pic
                                                            </a>
                                                        </div>
                                                        <div className="col-12 col-lg-6 col-xl-6">
                                                            {imgUrl && <div>
                                                                <img src={imgUrl} style={{ height: "100px", width: "100px" }} />
                                                                {selectedFile != null || imgUrl != null ?
                                                                    <Button title="Remove" className="btn btn-danger waves-effect waves-light removeButton btn-sm" onClick={() => removeFile(imgName, "img")}>X</Button>
                                                                    : ""}
                                                            </div>}
                                                            {/* <label className="uploadFileName" name="fileName" >{textUrl}</label> */}

                                                        </div>
                                                    </div>
                                                    <div className="col-12 row">
                                                        <input id="uploadDoc" type="file" name="document" accept="application/.pdf,.doc,.docx" onChange={handledocChange} style={{ "display": "none" }} multiple />
                                                        <div className="uploadBlock mb-4 mt-3">
                                                            <a href="#" className="uploadLink" value="Upload File" onClick={handledocClick} style={{ marginTop: "-20px" }} >
                                                                <em className="fa fa-arrow-up" ></em><br></br>Upload Document
                                                            </a>
                                                        </div>
                                                        <div className="ml-3 row col-6" style={{ marginTop: "20px" }}>
                                                            {docUrl && docUrl.map((url, i) => {
                                                                return (
                                                                    <>
                                                                        <div key={Math.random()} style={{ display: "block" }} >
                                                                            <a target='_blank' href={process.env.REACT_APP_APIURL + "static/" + url}>{url}</a>
                                                                            <Button style={{ marginTop: "0px", marginBottom: "1px" }} title="Remove" className="btn btn-danger ml-2 waves-effect waves-light removeButton btn-sm" onClick={() => removeFile(url, "doc")}>X</Button>
                                                                        </div><br />
                                                                    </>
                                                                )
                                                            })}
                                                        </div>
                                                    </div>
                                                    <div className="col-12">
                                                        <Button type="reset" className="btn btn-danger waves-effect waves-light float-right ml-2" onClick={() => clearData()}>Cancel</Button>
                                                        <Button type="submit" className={(params.id ? "btn-success" : "btn-light") + " btn waves-effect waves-light float-right"}>{params.id ? "Update" : "Add New"}</Button>
                                                    </div>
                                                </div>
                                            </Form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    );
                }}
            </Formik>
        </div>
    )
}
export default PatientsAdd;