import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { Post } from "../../common/ajax";
import { immediateToast } from "izitoast-react";
import moment from "moment";

//import "./App.css";
import "./meetingstyles.css";

import {
  CameraVideoTrack,
  MicrophoneAudioTrack,
  AgoraRTCClient,
  AgoraRTCRemoteUser,
} from "agora-rtc-sdk-ng";

import {
  VERSION,
  createClient,
  createCameraVideoTrack,
  createMicrophoneAudioTrack,
  onCameraChanged,
  onMicrophoneChanged,
} from "agora-rtc-sdk-ng/esm";
import React from "react";

console.log("Current SDK VERSION: ", VERSION);

onCameraChanged((device) => {
  console.log("onCameraChanged: ", device);
});
onMicrophoneChanged((device) => {
  console.log("onMicrophoneChanged: ", device);
});

const client = createClient({
  mode: "rtc",
  codec: "vp8",
});
let audioTrack = MicrophoneAudioTrack;
let videoTrack = CameraVideoTrack;
const VideoMeeting = () => {
  const [isAudioOn, setIsAudioOn] = useState(false);
  const [isVideoOn, setIsVideoOn] = useState(false);
  const [isAudioPubed, setIsAudioPubed] = useState(false);
  const [isVideoPubed, setIsVideoPubed] = useState(false);
  const [isVideoSubed, setIsVideoSubed] = useState(false);
  const [isJoined, setIsJoined] = useState(false);
  const [startMeeting, setstartMeeting] = useState(false);
  const [errorMsg, seterrorMsg] = useState("");
  const [doctorName, setdoctorName] = useState("");
  const [patientName, setpatientName] = useState("");
  const [appid, setappid] = useState("");
  const [token, settoken] = useState("");
  const [totalTime, settotalTime] = useState("");
  const [remainingTime, setremainingTime] = useState("");
  const [meetingRunning, setmeetingRunning] = useState(false);
  const [endTime, setendTime] = useState(0);
  const { id } = useParams();
  let role = JSON.parse(window.localStorage.getItem("AdminRole"));
  let mediaType = "audio";
  useEffect(() => {
    getMeetingDetail();
  }, [id]);

  useEffect(() => {
    if (appid != "" && startMeeting) {
      joinChannel();
      setmeetingRunning(true);
    }
  }, [appid]);

  const channel = id;

  const showTost = (msg, theme) => {
    theme = theme || "info";
    immediateToast(theme, {
      message: msg,
      timeout: 3000,
      position: "topRight",
    });
  };
  useEffect(() => {
    if (totalTime != "") {
      var tInterval = setInterval(() => {
        var now = new Date();

        if (now < endTime) {
          var diff = endTime - now;
          setremainingTime(getTimes(diff));
          setmeetingRunning(true);
        } else {
          setmeetingRunning(false);
          clearInterval(tInterval);
          alert("Appointment time is over.");
          leaveChannel();
        }
      }, 1000);
    }
  }, [totalTime, remainingTime]);
  const getMeetingDetail = async () => {
    await Post(`appointment/AudioVideoMeeting`, {
      channelName: id,
      apptId: id,
    })
      .then((res) => {
        if (res.success) {
          var data = res.data;
          var docDetail = data.detail.doctorId;
          var patDetail = data.detail.patientId;

          if (data.detail.conversationType == "Chat") {
            alert("Not Audio Video Meeting");
            window.location.replace("/dashboard");
          }
          mediaType = data.detail.conversationType.toLowerCase();
          if (role == "Doctor") {
            setdoctorName(
              "Dr. " + docDetail.firstName + " " + docDetail.lastName
            );
          } else {
            setpatientName(patDetail.firstName + " " + patDetail.lastName);
          }

          var curtDate = new Date();

          var startDt = new Date(data.startTime);
          var endDt = new Date(data.endTime);
          setendTime(endDt);
          let diff = endDt - startDt;

          settotalTime(getTimes(diff));
          var nDate = startDt.setMinutes(startDt.getMinutes() - 5);
          startDt = new Date(nDate);
          if (curtDate > startDt && curtDate < endDt) {
            setappid(data.APP_ID);
            settoken(data.token);
            setstartMeeting(true);
          } else {
            alert("Invalid date time slot");
          }
        } else {
          showTost(res.message, "warning");
        }
        //setisLoading(false);
      })
      .catch((err) => {
        //setisLoading(false);
        alert("Error in generating meetinglink." + err);
      });
  };

  //formatting total and remaining time
  const getTimes = (diff) => {
    let ss = Math.floor(diff / 1000) % 60;
    let mm = Math.floor(diff / 1000 / 60) % 60;
    let hh = Math.floor(diff / 1000 / 60 / 60);
    ss = ss.toString().length > 1 ? ss : "0" + ss;
    mm = mm.toString().length > 1 ? mm : "0" + mm;
    hh = hh.toString().length > 1 ? hh : "0" + hh;
    return hh + ":" + mm + ":" + ss;
  };
  const turnOnCamera = async (flag) => {
    flag = flag ?? !isVideoOn;
    setIsVideoOn(flag);

    if (videoTrack) {
      return videoTrack.setEnabled(flag);
    }
    videoTrack = await createCameraVideoTrack();
    videoTrack.play("camera-video");
  };

  const turnOnMicrophone = async (flag) => {
    flag = flag ?? !isAudioOn;
    setIsAudioOn(flag);

    if (audioTrack) {
      return audioTrack.setEnabled(flag);
    }

    audioTrack = await createMicrophoneAudioTrack();
    // audioTrack.play();
  };

  const joinChannel = async () => {
    if (!channel) {
      channel = "react-room";
    }

    if (isJoined) {
      await leaveChannel();
    }

    client.on("user-published", onUserPublish);

    await client.join(appid, channel, token || null, null);
    setIsJoined(true);
    turnOnMicrophone(true);

    if (mediaType === "video") {
      turnOnCamera(true);
      publishVideo();
    }
    publishAudio();
  };

  const leaveChannel = async () => {
    setIsJoined(false);
    setIsAudioPubed(false);
    setIsVideoPubed(false);
    setIsJoined(false);
    turnOnCamera(false);
    turnOnMicrophone(false);
    await client.leave();
  };

  const onUserPublish = async (user) => {
    if (mediaType === "video") {
      const remoteTrack = await client.subscribe(user, mediaType);
      remoteTrack.play("remote-video");
      setIsVideoSubed(true);
    }
    if (mediaType === "audio") {
      const remoteTrack = await client.subscribe(user, mediaType);
      remoteTrack.play();
    }
  };

  const publishVideo = async () => {
    await turnOnCamera(true);

    if (!isJoined) {
      //await joinChannel();
    }
    await client.publish(videoTrack);
    setIsVideoPubed(true);
  };

  const publishAudio = async () => {
    await turnOnMicrophone(true);

    if (!isJoined) {
      // await joinChannel();
    }

    await client.publish(audioTrack);
    setIsAudioPubed(true);
  };

  return (
    <>
      <div>
        {isJoined ? (
          <div className="videoBlock">
            <div className={isVideoSubed ? "currentUsr" : "remoteUsr"}>
              <label>{role == "Doctor" ? doctorName : patientName}</label>
              <video
                className="videoUsr"
                id="camera-video"
                hidden={isVideoOn ? false : true}
              ></video>
            </div>
            <div className={"remoteUsr"} hidden={isVideoSubed ? false : true}>
              <label>{role == "Doctor" ? doctorName : patientName}</label>
              <video
                id="remote-video"
                className="videoUsr"
                hidden={isVideoSubed ? false : true}
              ></video>
            </div>
            <div className="videoBottom">
              <div className="col-5 videoBottomdisplay">
                {mediaType === "video" && (
                  <button
                    onClick={() => turnOnCamera()}
                    className={`btnMeeting videoFooterButton ${
                      isVideoOn ? "btnOn" : ""
                    }`}
                  >
                    <i className={`i-camera `}></i>
                  </button>
                )}
                <button
                  onClick={() => turnOnMicrophone()}
                  className={`btnMeeting videoFooterButton ${
                    isAudioOn ? "btnOn" : ""
                  }`}
                >
                  <i className={`i-microphone `}></i>
                </button>
              </div>
              <div className="col-3 videoBottomdisplay">
                <label className="videotimeLabel">
                  <i
                    className="fa fa-clock"
                    style={{ marginRight: "10px" }}
                  ></i>
                  {remainingTime}/{totalTime}
                </label>
              </div>
              <button className="btn btn-danger btnEnd" onClick={leaveChannel}>
                End Call
              </button>
            </div>
          </div>
        ) : (
          <div style={{ textAlign: "center", marginTop: "20%" }}>
            <label className="invalidLabel">{errorMsg}</label>
            <a href="/dashboard" className="btn btn-info ">
              Back
            </a>
            {meetingRunning && (
              <a
                href={`/meeting/${id}`}
                className="btn btn-primary "
                style={{ marginLeft: "5px" }}
              >
                Rejoin
              </a>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default VideoMeeting;
